<template>
    <v-layout justify-center>
        <v-responsive max-width="1280px" width="100%">

            <v-expand-transition>
                <v-card v-show="showSearch" class="elevation-1 mx-auto">
                    <v-card-title class="subtitle-2 font-weight-bold">기간별 조회</v-card-title>
                    <v-card-text>
                        <v-row class="mt-3">
                            <v-btn class="ml-2" @click="setDate('전체')">전체</v-btn>
                            <v-btn class="ml-2" @click="setDate('당일')">당일</v-btn>
                            <v-btn class="ml-2" @click="setDate('1주일')">1주일</v-btn>
                            <v-btn class="ml-2" @click="setDate('1개월')">1개월</v-btn>
                            <v-btn class="ml-2" @click="setDate('6개월')">6개월</v-btn>
                            <v-btn class="ml-2" @click="setDate('12개월')">12개월</v-btn>
                        </v-row>
                    </v-card-text>
                    <v-card-title class="subtitle-2 font-weight-bold">일자별 조회</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6" class="py-0">
                                <v-date-field v-model="filter.createdAt[0]" label="작성일자" persistent-placeholder hide-details></v-date-field>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-0">
                                <v-date-field v-model="filter.createdAt[1]" label="작성일자" persistent-placeholder hide-details></v-date-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" rounded text @click="(page = 1) && search()"><v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색</v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>

            <v-row>
                <v-col cols="auto">
                    <v-img :src="product?.thumb?.src" width="90" height="45" contain></v-img>
                </v-col>
                <v-col cols="auto">
                    {{ product?.name }}
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto">
                    <v-btn color="white" class="green--text px-2 ml-1" @click="excel"> <v-icon>mdi-file-excel</v-icon> URL 엑셀 다운로드 </v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-btn color="white" class="green--text px-2 ml-1" @click="dialog = true"> <v-icon>mdi-file-excel</v-icon> 식별번호 엑셀 업로드 </v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-btn color="white" class="green--text px-2 ml-1" href="/res/sample/sample.xlsx" download> 업로드 샘플 </v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-btn color="white" class="red--text px-2 ml-1" @click="remove">삭제 </v-btn>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="auto">
                    검색된 URL: {{ totalCount }}
                </v-col>
            </v-row>

            <v-data-table :items="serials" :headers="serialHeaders" disable-sort disable-pagination hide-default-footer class="elevation-1">
                <template #[`item.no`]="{ item }">
                    {{ (totalCount - serials.indexOf(item))  - (page - 1) * limit }}
                </template>
                <template #[`item.url`]="{ item }">
                    <div @click="move(item.url)">
                        <button>{{ item.url }}</button>
                    </div>
                </template>
                <template #[`item.createdAt`]="{ item }">
                        {{ $dayjs(item.createdAt).format("YYYY-MM-DD") }}
                </template>
            </v-data-table>
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12" @input="search()"></v-pagination>

            <v-btn bottom color="accent" fab fixed right @click="create()">
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
        </v-responsive>
        <v-dialog v-model="dialog" max-width="480px" persistent>
			<v-card v-bind="{ loading }">
				<v-card-title class="subtitle-1">식별번호 엑셀 업로드</v-card-title>
				<v-card-text>
					<v-text-field v-model="file.name" label="파일" persistent-placeholder hide-details readonly @click="$refs?.excel?.$refs?.input?.click()"></v-text-field>
					<v-file-input accept=".xlsx" class="d-none" ref="excel" @change="change"></v-file-input>
                    <div v-if="loading" class="mt-2" style="color: red;">업로드시 시간이 걸릴 수 있습니다.</div>
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="red" @click="dialog = false">
                        <span>취소</span>
                    </v-btn>
					<v-btn color="primary" @click="save">
                        <span>저장</span>
                    </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
    </v-layout>
</template>

<script>
import api from "@/api";
import XLSX from "xlsx";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";

export default {
    components: {
        VDateField,
    },
    data() {
        return {
            loading: false,
			dialog: false,
            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            showSearch: true,

            category: null,

            filter: {
                code: this.$route.query.code,
                category: this.$route.query.category,
                searchKey: this.$route.query.searchKey,
                searchValue: this.$route.query.searchValue,
                createdAt: [(this.$route.query.createdAt || [])[0], (this.$route.query.createdAt || [])[1]],
            },

            page: +this.$route.query.page || 1,
            pageCount: 0,
            totalCount: 0,
            limit: 10,

            serials: [],
            product: {},
            file: {
                name: null
            },

            workSheets: null,

            searchKeys: [
                { text: "전체", value: null },
                { text: "제품명", value: "name" },
            ],
            searchKey: null,
            searchValue: null,

			serialHeaders: [
				{ text: "no", align: "center", width: 40, value: "no" },
				{ text: "등록일", align: "center", width: 50, value: "createdAt" },
				{ text: "식별번호", align: "center", width: 50, value: "code" },
				{ text: "URL", align: "center", width: 300, value: "url" },
			],
        };
    },
    mounted() {
        this.init();
    },
    watch: {
        $route() {
            this.search(false);

            Object.assign(this.filter, this.$route.query);
            this.page = +this.$route.query.page || 1;
        },
    },
    methods: {
        init() {
            this.search(false);
        },
        async setDate(type) {
            if (type === "전체") {
                this.filter.createdAt = [ null, null ]
            } else if (type === "당일") {
                this.filter.createdAt = [this.$dayjs().format("YYYY-MM-DD"), this.$dayjs().format("YYYY-MM-DD") ];
            } else if (type === "1주일") {
                this.filter.createdAt = [this.$dayjs().subtract(1, "week").format("YYYY-MM-DD"), this.$dayjs().format("YYYY-MM-DD")]
            } else if (type === "1개월") {
                this.filter.createdAt = [this.$dayjs().subtract(1, "month").format("YYYY-MM-DD"), this.$dayjs().format("YYYY-MM-DD")]
            } else if (type === "6개월") {
                this.filter.createdAt = [this.$dayjs().subtract(6, "month").format("YYYY-MM-DD"), this.$dayjs().format("YYYY-MM-DD")]
            } else if (type === "12개월") {
                this.filter.createdAt = [this.$dayjs().subtract(1, "year").format("YYYY-MM-DD"), this.$dayjs().format("YYYY-MM-DD")]
            }
            this.init();
        },
		change(file) {
            if (!file) return;
            let fileName = file.name.split(".");
            if (fileName[fileName.length - 1] !== "xlsx") {
                return alert("엑셀파일만 가능합니다");
            }
            this.file = file
            let reader = new FileReader();
            reader.onload = () => {
                var workSheets = [];
                var workBook = XLSX.read(reader.result, { type: "binary" });
                workBook.SheetNames.forEach((sheetName) => {
                    let sheet = XLSX.utils.sheet_to_json(workBook.Sheets[sheetName]);
                    workSheets.push(sheet);
                });

                this.workSheets = workSheets[0];
            };
            reader.readAsBinaryString(file);
		},
        async search(routable = true) {
            try {
                if (routable) {
                    this.$router.push({
                        query: Object.assign({}, this.filter, { page: this.page }),
                    });
                }

                let { product } = await api.console.products.get({ _id: this.$route.params._product });
                this.product = product;

                var { summary, serials } = await api.console.products.serials.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: { ...this.filter, _product: this.product._id },
                });

                this.pageCount = Math.ceil(summary.totalCount / this.limit);
                this.totalCount = summary.totalCount
                this.serials = serials;

            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        create() {
            this.dialog = true;
        },
        edit(product) {
            this.product = product;
			this.dialog = true;
        },
		async save() {
            try {
                if(this.loading) return;
                this.loading = true;
                for (let i = 0; i < this.workSheets.length; i++) {
                    await api.console.products.serials.post({ _id: this.product._id, ...this.workSheets[i] })
                }
                alert("URL이 등록되었습니다.")
                this.dialog = false;
                this.init()
            } catch(error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            } finally {
                this.loading = false;
            }
		},
        async remove() {
            try {
                if (confirm(`검색된 ${this.totalCount}개의 URL을 삭제하시겠습니까?`)) {
                    await api.console.products.serials.delete({
                        params: this.filter,
                        _id: this.product._id
                    });
                    await this.search(false);

                    alert("삭제되었습니다");
                }
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        move(url) {
            window.open(url)
        },
        async excel() {
            if (confirm(`검색된 ${this.totalCount}개의 URL을 다운로드 하시겠습니까?`)) {
                try {
                    var { summary, serials } = await api.console.products.serials.gets({
                        headers: {
                            limit: 0,
                        },
                        params: {...this.filter, _product: this.product._id},
                    });
                    var rows = serials.map((serial) => ({
                        URL: serial.url
                    }));

                    var workbook = new XLSX.utils.book_new();
                    var worksheet = XLSX.utils.json_to_sheet(rows);

                    XLSX.utils.book_append_sheet(workbook, worksheet, "new");
                    XLSX.writeFile(workbook, "URL.xlsx");
                } catch (error) {
                    console.error(error);
                    alert(error.response ? error.response.data.email : error.email);
                }
            }
        },
    },
};
</script>
